body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #EEE;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-frame {
  margin: 0 auto;
  margin-top: 20px;
  height: 300px;
  width: 120px;
  display: grid;
  grid-template-rows: 80px 1fr 1fr 1fr;
  grid-row-gap: 15px;
  background-color: #ffffff;
}

.frame {
  font-style: "Roboto";
  margin: 0 auto;
  width: 600px;
  font-size: 16px;
  line-height: 1.4;
  display: block;
  color: black;
  background-color: #EEE;
  padding-top: 55px;
}

.view-frame {
  margin: 0 auto;
}

#gameContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 340px;
  position: relative;
}

#scoreCanvas {
  background: #eee;
  display: block;
  margin: 0 auto;
  border: solid 5pt darkgray;
  z-index: 1;
  position: absolute;
  left: auto;
  top: 0px;
}

#gameCanvas {
  display: block;
  margin: 0 auto;
  border: solid 5pt darkgray;
  z-index: 2;
  position: absolute; 
  left: auto;
  top: 0px;
}

/*
.navButton {
  height: 60%;
  width: 100%;
  background-color: #FFFFFF;
  font-size: 24px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 2px;
}
*/

h1, h2, h3, h4, h5, h6 {
  line-height: 1;
}

.navbar {
  font-style: "Roboto";
  margin: 0;
  padding: 0;
  height: 42px;
  background-color: #1d4b56;
  color: white;
  width: 100%;
  position: fixed;
  overflow: auto;
}

.nav-content {
  margin: 0 auto;
  width: 80%;
  max-width: 600px;
}

.nav-content .nav-item {
  color: white;
  text-decoration: none;
  cursor: pointer;
  display: block;
  float: left;
  padding-right: 40px;
  height: 100%;

  font-size: 18px;
  font-weight: 500px;

  padding-top: 9px;
  padding-right: 10px;
  padding-bottom: 9px;
  padding-left: 10px;
}

.nav-content .nav-item-active {
  background-color: #EEE;
  color: black;

}

.nav-content .nav-item:hover {
  background-color: #4b7783;
}

.nav-content .nav-item-active:hover {
  background-color: #EEE;
  color: black;
}

#title {
  color: white;
  font-size: 28px;

  text-shadow: 1px 1px 1px black;

  text-decoration: none;

  display: -webkit-flex;

  display: flex;
  float: left;
  -webkit-align-items: center;
          align-items: center;

  padding-right: 35px;
  height: 100%;
  margin-right: 5px;
}

/*
.link {
  text-decoration: none;
  color: #000000;
  text-align: center;
}
*/

.navButton {
  text-align: center;

  display: inline-block;

  -webkit-user-select:none;

     -moz-user-select:none;

      -ms-user-select:none;

          user-select:none;

  cursor: pointer;

  width: 100%;
  height: 100%;

  font-weight: 400;
  font-size: 24px;

  color: #000000;
  background-color: #f2fcff;

  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}



.navButton:focus {
  outline: none;
}

.project-list {
   display: grid;
   grid-template-rows: 100px 100px;
   grid-row-gap: 10px;

   text-decoration: none;
}

.project-item {
  display: grid;
  grid-template-columns: 300px 1fr;

  height: 100px;

  color: black;
  text-decoration: none;
  
  border: 2px solid #1d4b56;
}

.project-list img {
  width: 90%;
  
}

.project-list #ticImage {
  width: 90%;

  border: 5px solid #255C69;
}

.project-list .project-desc {
  margin: 0 auto;
  -webkit-align-self: center;
          align-self: center;
  justify-self: center;

  text-decoration: none;

  text-align: center;
}

#snakeImage {
  border: 5px solid darkgray;
}



.about {
  line-height: 1.4;
  text-align: left;
}

.contact {
  line-height: 1.4;
  text-align: left;
}

.tic-description {
  margin-top: 20px;
}

.no-decoration-link {
  text-decoration: none;
}
/*
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
.game {
  margin: 0 auto;
  height: 350px;
  width: 250px;
  display: grid;
  grid-template-rows: 50px 1fr 50px;
  background-color:#255C69;
  font-family: "Roboto";
  box-shadow: 0 2px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.board {
  margin: 0 auto;
  width: 250px;
  height: 250px;
  background-color: #bcbcbc;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
}

.space {
  color: black;
  background-color: white;
  font-size: 57px;
  cursor: pointer;
  text-align: center;
}

.space:focus {
  outline: none;
}

.status {
  color: white;
  background-color: #255C69;
  display: block;
  -webkit-align-self: center;
          align-self: center;
  text-align: center;
}

#statusShape {
  margin-left: 5px;
  float: left
}

#statusTurn {
  margin-right: 5px;
  float: right
}

#statusTitle {
  text-align: center;
}

.panel {
  color: white;
  background-color: #255C69;
  display: flow-root;
  -webkit-align-self: center;
          align-self: center;
  text-align: center
}

#findGameButton {
  text-align: center;
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;

  -webkit-user-select:none;

     -moz-user-select:none;

      -ms-user-select:none;

          user-select:none;

  cursor: pointer;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 100px;
  max-height: 40px;

  font-weight: 500;

  color: #000000;
  background-color: #d9eff4;

  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

}

#findGameButton:hover {
  background-color: #edfbff;
}

#findGameButtonInactive {
  text-align: center;
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 100px;
  max-height: 40px;

  font-weight: 500;

  color: #c9c9c9;
  background-color: #e8e8e8;

  border-radius: 4px;
}

