/*
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
.game {
  margin: 0 auto;
  height: 350px;
  width: 250px;
  display: grid;
  grid-template-rows: 50px 1fr 50px;
  background-color:#255C69;
  font-family: "Roboto";
  box-shadow: 0 2px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.board {
  margin: 0 auto;
  width: 250px;
  height: 250px;
  background-color: #bcbcbc;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
}

.space {
  color: black;
  background-color: white;
  font-size: 57px;
  cursor: pointer;
  text-align: center;
}

.space:focus {
  outline: none;
}

.status {
  color: white;
  background-color: #255C69;
  display: block;
  align-self: center;
  text-align: center;
}

#statusShape {
  margin-left: 5px;
  float: left
}

#statusTurn {
  margin-right: 5px;
  float: right
}

#statusTitle {
  text-align: center;
}

.panel {
  color: white;
  background-color: #255C69;
  display: flow-root;
  align-self: center;
  text-align: center
}

#findGameButton {
  text-align: center;
  justify-self: center;
  align-self: center;

  user-select:none;

  cursor: pointer;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 100px;
  max-height: 40px;

  font-weight: 500;

  color: #000000;
  background-color: #d9eff4;

  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

}

#findGameButton:hover {
  background-color: #edfbff;
}

#findGameButtonInactive {
  text-align: center;
  justify-self: center;
  align-self: center;

  user-select: none;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 100px;
  max-height: 40px;

  font-weight: 500;

  color: #c9c9c9;
  background-color: #e8e8e8;

  border-radius: 4px;
}
